export interface ConfigNavigation {
  SPA?: true;
  baseUrl?: string;
  newUpdatePaymentMethod?: boolean;
  newLandlineURL?: boolean;
  showDevTools?: boolean;
  alwaysShowLeftNav?: boolean;
  newMobileURL?: boolean;
  newBroadbandURL?: boolean;
  newTvURL?: boolean;
}

export interface ConfigInvoices {
  monthsToShow?: number;
}

export interface ConfigMobile {
  host?: string;
  freeDataInfo?: {
    startDate: string;
    endDate: string;
  };
}

export interface Odin {
  host?: string;
  dockerHost?: string;
  xcareHost?: string;
  segment?: string;
  bcInstance?: string;
}

export interface MicroServices {
  host?: string;
  version?: number;
}

export interface Config {
  debug?: boolean;
  port?: number;
  odin?: Odin;
  microServices?: MicroServices;
  tracking?: 'prod' | 'dev';
  navigation?: ConfigNavigation;
  invoices?: ConfigInvoices;
  mobile?: ConfigMobile;
  redirectIfNotLoggedIn?: boolean;
  loadMockServiceWorker?: boolean;
  SENTRY_INSTANCE?: string;
  netcracker?: {
    host: string;
    graphQlEndpoint: string;
  };
  ciam?: {
    host?: string;
  };
}

// sets values for test environment
const stagingConfig: Config = {
  odin: {
    bcInstance: 'bcpp',
  },
  navigation: {
    baseUrl: '',
    newUpdatePaymentMethod: true,
    newLandlineURL: true,
    showDevTools: true,
    alwaysShowLeftNav: true,
  },
  tracking: 'dev',
  redirectIfNotLoggedIn: false,
  loadMockServiceWorker: true,
  SENTRY_INSTANCE: 'https://26ccf4c1d6074096b25a7cfd7873e742@o341752.ingest.sentry.io/5648723',
};
// test environment end

// sets values for prod environment
const productionConfig: Config = {
  odin: {
    bcInstance: 'bcprod',
  },
  navigation: {
    baseUrl: 'https://ny-selvbetjening.yousee.dk',
    newUpdatePaymentMethod: false,
    newLandlineURL: true,
    showDevTools: false,
    alwaysShowLeftNav: false,
  },
  tracking: 'prod',
  redirectIfNotLoggedIn: true,
  loadMockServiceWorker: false,
  SENTRY_INSTANCE: 'https://26ccf4c1d6074096b25a7cfd7873e742@o341752.ingest.sentry.io/5648723',
  netcracker: {
    host: 'https://yousee.dk',
    graphQlEndpoint: '/shop/api/graphql-server/graphql',
  },
  ciam: {
    host: 'https://login.yousee.dk',
  },
};
// prod environment end

export interface ConfigProps {
  sets: {
    default: Config;
    staging: Config;
    production: Config;
  };
}

export const config: ConfigProps = {
  sets: {
    default: {
      odin: {
        xcareHost: import.meta.env.PUBLIC_ODIN_XCARE_URL,
        segment: 'yousee',
        host: import.meta.env.PUBLIC_ODIN_URL,
        dockerHost: import.meta.env.PUBLIC_ODIN_DOCKER_URL,
      },
      invoices: {
        monthsToShow: 18,
      },

      navigation: {
        SPA: true,
        newMobileURL: true,
        newBroadbandURL: true,
        newTvURL: true,
        newLandlineURL: true,
      },

      microServices: {
        host: import.meta.env.PUBLIC_MICROSERVICES_URL,
        version: 1,
      },

      mobile: {
        host: import.meta.env.PUBLIC_MOBILE_API_URL,
        freeDataInfo: {
          startDate: '2022-06-01',
          endDate: '2022-09-01',
        },
      },
      netcracker: {
        host: 'https://nd-cloud-portal-uat03-bss.non-prod04.nc.nuuday.nu',

        graphQlEndpoint: '/shop/api/graphql-server/graphql',
      },
      ciam: {
        host: 'https://test.ciam.nuuday.dk',
      },
    },
    staging: { ...stagingConfig },
    production: { ...productionConfig },
  },
};
