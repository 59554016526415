import { ThemeProvider } from '@mui/material/styles';
import youseeDnaTheme from '@nuuday/yousee-dna';
import { Footer, Navigation } from '@nuuday/ys-navigation';
import { BrowserTracing } from '@sentry/browser';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import '@youseedk/dna-react/dist/main.css';
import { fetchMyProfile } from 'api/users';
import { AxiosError } from 'axios';
import ErrorPage from 'pages/ErrorPage';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { getConfig, setConfig } from 'shared/config';
import { cookies } from 'shared/utils/storage/cookies';
import { LOGOUT_ALL_DEVICES_PATH } from 'utils/auth';
import { featLoginWithCiamPing } from 'utils/feature-toggle/feat-login-with-ciam-ping';
import { featNetcrackerLive } from 'utils/feature-toggle/feat-netcracker-live';
import { identity, setIdentity } from 'utils/identity';
import { hasOnlyNetCrackerProducts } from 'utils/misc';
import { isUsingMock } from 'utils/mock';
import AppRoot, { history } from './AppRoot';
import { config } from './config';
import { isUserAgent } from './utils/user-agent';

// Necessary polyfill because contentful-client uses a library that expects global to be available
(window as any).global = window;

const isAnyMobileApp = isUserAgent(['youseeSelfcareApp', 'netcrackerApp']);

const handleNetcrackerRedirect = (): boolean => {
  const isLogoutPath = window?.location?.pathname.includes(LOGOUT_ALL_DEVICES_PATH);

  if (featNetcrackerLive() && hasOnlyNetCrackerProducts() && !isAnyMobileApp && !isLogoutPath) {
    /*if (process.env.NODE_ENV !== 'production') {
      if (
        !confirm(
          'Do you want to be redirected to Netcracker Ecare? This message is only shown in test environments.',
        )
      ) {
        return false;
      }
    }*/
    const ecareHost = getConfig()?.netcracker?.host ?? 'https://yousee.dk';
    window?.location?.replace(`${ecareHost}/ecare`);
    return true;
  }
  return false;
};

(async () => {
  setConfig(config, process.env.NODE_ENV === 'production' ? 'production' : 'staging');

  if (isUsingMock) {
    const { worker, workerOptions } = await import('./mocks/browser');
    await worker.start(workerOptions);
  }
  await fetchMyProfile()
    .then((profile) => {
      setIdentity({ ...profile, isLoggedIn: true, isCheckingAuth: false });
    })
    .catch(() => {
      setIdentity({ ...identity, isLoggedIn: false, isCheckingAuth: false });
    });

  if (import.meta.env.MODE !== 'production') {
    const bcInstanceCookieValue = featLoginWithCiamPing() ? 'test11jwt' : 'test11';

    const currentCookie = cookies.get('BcInstance', true);
    if (currentCookie !== bcInstanceCookieValue) {
      if (currentCookie) {
        cookies.remove('BcInstance');
      }
      cookies.set('BcInstance', bcInstanceCookieValue, 24, {
        secure: 'true',
        SameSite: 'None',
        domain: 'yousee.dk',
      });
    }
  }

  const sentryKey = getConfig().SENTRY_INSTANCE;
  Sentry.init({
    enabled: sentryKey
      ? process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
      : false,
    dsn: sentryKey,
    // commented out session replay for now as we are seeing some client errors because of it
    // should be enabled again once we have gotten cookie consent
    /*replaysSessionSampleRate: 0.001,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,*/

    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new ExtraErrorDataIntegration({
        // Limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 5,
      }),
      // commented out session replay for now as we are seeing some client errors because of it
      /*new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),*/
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.001,
    normalizeDepth: 10,
    release: import.meta.env.PUBLIC_SENTRY_RELEASE,
    allowUrls: [/yousee\.dk/, /nuuday\.nu/],
    ignoreErrors: [
      'NetworkError when attempting to fetch resource', // when user is not logged in this happens in firefox
      'Load failed', // when user is not logged in this happens on osx and ios
      'TypeError: cancelled', // when user is not logged in this happens on ios
      'TypeError: annulleret', // when user is not logged in this happens on ios
      '[alloy]', // tracking scripts errors - have contacted adobe group in nuuday but no response
      'ECID', // // tracking scripts errors - have contacted adobe group in nuuday but no response - full error: "Cannot read properties of undefined (reading 'ECID')"
      'change_ua', // tracking scripts errors - have contacted adobe group in nuuday but no response - full error: "Identifier 'change_ua' has already been declared"
      't.identity is undefined', // tracking scripts errors - have contacted adobe group in nuuday but no response - full error: "t.identity is undefined"
      'Unauthorized - missing token', // when user is not logged in
      'Unauthorized - token has expired', // when user token is expired,
      'The operation was aborted', // when user navigates away from page before request is finished
      'API request - status: 0', // when request is cancelled
      'API response - status: 403', // token is expired
      'API response - status: 400, transaction: PUT {id}/profile/password, message: Request failed with status code 400', // previous password is wrong
      'API response - status: 400, transaction: POST {id}/recovery/activate, message: Request failed with status code 400', // recovery code is wrong
      'API response - status: 404, transaction: GET public/accounts/{id}/invoice/{id}, message: Request failed with status code 404', // invoice is not found by customer's lookup
      'Failed to fetch dynamically imported module', // on deploys - the page will reload but this error is sometimes shown
      "undefined is not an object (evaluating 'a.j')", // from the bm.js script - loaded from web.telemetric.dk
      'viewthroughconversion', // https://googleads.g.doubleclick.net/pagead/viewthroughconversion/1063033614 fails to load
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;

      if (error && (error as AxiosError).message?.startsWith('API')) {
        if (Object.keys(event.extra ?? {}).includes('scoped')) {
          // scoped API events are the ones that should be sent to Sentry, the other one is the
          // bubbled up error which we can ignore
          return event;
        } else {
          return null;
        }
      }

      // ignore errors that have
      // - no stack information
      // - are from anonymous functions (outside our codebase)
      // - are loadable errors throwing end of script errors
      // - are gtag errors
      if (
        !event.exception?.values?.[0]?.stacktrace?.frames?.length ||
        event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename === '<anonymous>' ||
        event.exception?.values?.[0]?.value === 'Unexpected end of script' ||
        (event.exception?.values?.[0]?.stacktrace?.frames?.some(
          (frame) => frame?.filename?.includes('googletagmanager'),
        ) &&
          event.exception?.values?.[0]?.value === 'Failed to fetch') ||
        // https://googleads.g.doubleclick.net/pagead/viewthroughconversion/1063033614 fails to load
        event.exception?.values?.[0]?.stacktrace?.frames?.some(
          (frame) => frame?.filename?.includes('viewthroughconversion'),
        )
      ) {
        return null;
      }

      return event;
    },
  });
})().then(() => {
  // if the customer has only netcracker products, the customer will be redirected here and selfcare will not be rendered
  if (handleNetcrackerRedirect()) {
    return;
  }

  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript - from the official react docs

  root.render(
    <Sentry.ErrorBoundary
      fallback={
        <main data-hj-suppress className='ng-root white-bg'>
          <ThemeProvider theme={youseeDnaTheme}>
            <Navigation
              hideBasketIcon
              env={process.env.NODE_ENV === 'production' ? 'prod' : 'test'}
            />
            <ErrorPage />
          </ThemeProvider>
        </main>
      }>
      <ThemeProvider theme={youseeDnaTheme}>
        <AppRoot />
      </ThemeProvider>
      {!isUserAgent(['youseeSelfcareApp', 'netcrackerApp']) && <Footer />}
    </Sentry.ErrorBoundary>,
  );
});
