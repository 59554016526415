import {
  NetCrackerProductCharacteristicType,
  NetCrackerProductType,
  NetCrackerSubscriptionType,
} from 'api/netCrackerAPI/netCrackerApiTypes';
import {
  getAddress,
  getPhoneNumber,
  getProductName,
  getProductType,
  getUrl,
  hasPhoneNumber,
} from 'hooks/useMyColumbusSubscriptions/useMyColumbusSubscriptionsUtils';
import { getConfig } from 'shared/config';
import { post } from 'shared/utils/api';
import { featProxyGraphQL } from 'utils/feature-toggle/feat-graphql';
import { getIndividualId } from 'utils/misc';

const baseHost = 'CUSTOM';

const NetcrackerProductTypes = {
  '5d8ec1da-d4d7-430d-908f-8143cccb0e8b': 'Mobile',
  '9f6e0aec-6e65-4507-af0e-5964dd37f056': 'Mobile',
  '6c69770c-d1b6-4e44-aaeb-e58cf3583ed8': 'MobileBroadband',
  '74b03cde-54ef-425c-a6fb-d5957ebed88b': 'MobileBroadband',
  'be8ed97b-1c2f-4755-af36-e0d3ba52cb5f': 'Broadband',
  'e1530fb3-db45-43ed-a702-1457785a515e': 'Broadband',
  'd631d582-fda3-484c-bcde-6c61b6aae192': 'Broadband',
  'ac18e9e7-d89c-4861-a2a5-9b8a3b4c6caa': 'Tv',
  'b5013b72-bf95-47ac-b6b4-458f7511d778': 'Tv',
  'd7c1f142-9680-4e33-85ca-632aa7b7d7d4': 'Tv',
  '0fc85a23-f90e-4809-a0fa-9c7b32076473': 'Tv',
  '0708fa2c-27c7-426f-a03c-805229346b95': 'Tv',
  '697f6d6e-2a4e-4fcc-8f5e-ff5f3dfd9faa': 'Tv',
  '928bdd19-e495-46fe-894b-2e7cc0bfa941': 'Fixednet',
  '5949460d-9f89-42cb-b0d4-3faaf4922d5a': 'Fixednet',
  'c27a9a4b-6610-4ed8-94c3-bf0b30e917c8': 'NetBasis',
  'd532986b-bb75-4512-991d-4048811b5b94': 'NetBasis',
};
const PhoneNumberProductType = '0d707568-6ce6-4841-8436-cde84c23b780';

export const getListCustomersByIndividualId = async () => {
  const listCustomersByIndividualIdQuery = `query listCustomersByIndividualId($individualIdFilter: String!) {
    listCustomers @filter(filters: [$individualIdFilter, "engagedParty.@referredType=Individual"]) {
      id
      }
  }`;

  const listCustomersVariables = {
    individualIdFilter: `engagedParty.id=${getIndividualId()}`,
  };

  const listCustomersBody = JSON.stringify({
    query: listCustomersByIndividualIdQuery,
    variables: listCustomersVariables,
  });

  return postToGraphQl(listCustomersBody, featProxyGraphQL()).then(({ data }) => {
    if (!data.listCustomers || data.listCustomers.length === 0) {
      throw new Error('Customer not found');
    }

    return data.listCustomers[0].id;
  });
};

export const getProductListCustomersId = async (customerId: string) => {
  const productListQuery = `query listProducts($customerId: ID!) {
    listProducts(customerId: $customerId)
      @limit(limit: 100)
      @filter(
        filters: [
          "status=ACTIVE,COMPLETED,PLANNED,ACTIVE_INSTALLMENT,ACTIVATION_IN_PROGRESS,WAITING_FOR_PROCESSING,MODIFICATION_IN_PROGRESS,SUSPENDED,DISCONNECTION_IN_PROGRESS"
        ]
      ) {
      id
      name
      status
      parentProductId
      productFamily {
        id
      }
      characteristic {
        id
        name
        value
        businessType
      }
    }
  }`;

  const productListQuerVariables = {
    customerId,
  };

  const productListBody = JSON.stringify({
    query: productListQuery,
    variables: productListQuerVariables,
  });

  return postToGraphQl(productListBody, featProxyGraphQL()).then(({ data }) => {
    if (!data.listProducts) {
      throw new Error('no listproducts');
    }
    const products = data.listProducts.filter(
      (product) => NetcrackerProductTypes[product.productFamily.id] ?? null,
    );

    const helpers = data.listProducts.filter(
      (product) => product.productFamily.id === PhoneNumberProductType,
    );

    return mapDawnProduct(products, helpers);
  });
};

const postToGraphQl = async (data: string, proxy: boolean): Promise<any> => {
  const netCrackerHost = getConfig().netcracker?.host;
  const netCrackerGraphQlEndpoint = getConfig().netcracker?.graphQlEndpoint;
  const netCrackerUrl = `${netCrackerHost}${netCrackerGraphQlEndpoint}`;

  if (proxy) {
    return post({
      url:
        process.env.NODE_ENV === 'development'
          ? `http://localhost:3000/netcrackerApi${netCrackerGraphQlEndpoint}`
          : `/netcrackerApi${netCrackerGraphQlEndpoint}`,
      baseHost,
      requireAuth: true,
      data,
    });
  } else {
    return post({
      url: netCrackerUrl,
      baseHost,
      requireAuth: true,
      data,
    });
  }
};

const findCharacteristic = (product, key) =>
  product.characteristic?.find((characteristic) => characteristic.businessType === key);

const mapDawnProduct = (
  listProducts: NetCrackerProductType[],
  helpers?: NetCrackerProductType[],
): NetCrackerSubscriptionType[] => {
  const netCrackerProducts: NetCrackerProductType[] = listProducts;
  const netCrackerSubscriptions: NetCrackerSubscriptionType[] = [];
  const phoneNumberKey = 'PHONE_NUMBER';

  netCrackerProducts.forEach((product) => {
    const productType = NetcrackerProductTypes[product.productFamily.id] ?? null;
    if (productType === 'Mobile' || productType === 'MobileBroadband') {
      const characteristic = findCharacteristic(product, phoneNumberKey);
      let helperCharactristic;
      if (!characteristic) {
        const helper = helpers?.find((item) => item.parentProductId === product.id);

        helperCharactristic = findCharacteristic(helper, phoneNumberKey);
      }
      netCrackerSubscriptions.push(
        formatNetCrackerSubscription(
          product.id,
          productType,
          characteristic || helperCharactristic,
        ),
      );
    } else {
      netCrackerSubscriptions.push(formatNetCrackerSubscription(product.id, productType));
    }
  });

  return netCrackerSubscriptions;
};

const formatNetCrackerSubscription = (
  subscriptionId: string,
  productType: string,
  characteristic?: NetCrackerProductCharacteristicType,
): NetCrackerSubscriptionType => {
  const phoneNumber = characteristic
    ? characteristic.value[0][0] === '+'
      ? characteristic.value[0].substring(1)
      : characteristic.value[0]
    : '';

  const formattedNetCrackerSubscription: NetCrackerSubscriptionType = {
    isNetCrackerSubscription: true,
    subscriptionId,
    productType: getProductType(productType),
    productName: getProductName(productType),
    phoneNumber,
    value: phoneNumber,
    getAddress: function (): string {
      return getAddress(this.address);
    },
    hasPhoneNumber: function (): boolean {
      return hasPhoneNumber(this);
    },
    getPhoneNumber: function (): string {
      return getPhoneNumber(this, this.phoneNumber);
    },
    getUrl: function (): string {
      return getUrl([], this.productType, this, this.isNetCrackerSubscription);
    },
  };

  return formattedNetCrackerSubscription;
};
