import { getFeatureToggle } from 'utils/feature-toggle/index';

export const featProxyGraphQL = (): boolean => {
  if (process.env.NODE_ENV === 'production') {
    return true;
  }
  return getFeatureToggle({
    name: 'feat-proxy-graphql',
    enabledByDefault: false,
    allowedInProduction: true,
  });
};
